import type { SupabaseClient } from "@supabase/supabase-js";
import { format } from "date-fns";
import { z } from "zod";
import { type Database, type Month, type NotificationService, CustomError, logger } from "../../../index.ts";
import { generateNotificationText } from "../../utils/notification.ts";
export const workflowAdvancePaymentPaidSchema = z.object({
  creation_date: z.coerce.date(),
  talent_email: z.string().email(),
  amount: z.number().positive(),
});

export class PaidAdvancePaymentHandler {
  constructor(
    private readonly supabaseBypassingRLS: SupabaseClient<Database>,
    private readonly notificationService: NotificationService,
    private readonly formatDateForDB: (date: Date) => string,
    public readonly frontBaseUrl: string
  ) { }

  static getAdvancePaymentMonth(contractStartDate: Date, creationDate: Date): Month {
    const creationMonth = format(creationDate, "MMMM").toLocaleLowerCase() as Month;
    const monthBeforeCreationMonth = format(
      new Date(creationDate.getFullYear(), creationDate.getMonth() - 1, 1),
      "MMMM"
    ).toLocaleLowerCase() as Month;

    // If the contract started in the month preceding the current month and the advance request was made during the payroll period (between the 1st and the 6th)
    // The specified month and the current month
    const isContractStartBeforeThisMonth =
      contractStartDate.getFullYear() < creationDate.getFullYear() ||
      (contractStartDate.getFullYear() === creationDate.getFullYear() &&
        contractStartDate.getMonth() < creationDate.getMonth() &&
        creationDate.getDate() <= 6);
    if (isContractStartBeforeThisMonth) return monthBeforeCreationMonth;
    // If the contract started in the month preceding the current month and the advance request was made after the payroll period (after the 7th)
    // Or if the contract starts during the current month
    else return creationMonth;
  }

  async handle(body: unknown) {
    const parse = workflowAdvancePaymentPaidSchema.safeParse(body);
    if (!parse.success) throw new CustomError(parse.error.message, 400);

    const { creation_date: creationDate, talent_email: talentEmail, amount } = parse.data;

    const { error: errorTalent } = await this.supabaseBypassingRLS
      .from("contract")
      .select("id, talent(email)")
      .eq("talent.email", talentEmail);

    if (errorTalent) throw new CustomError(errorTalent.message, 500);

    // We allow an advance payment request
    // only if a signed contract for the month of the advance payment request exist
    const firstDayOfMonth = new Date(creationDate.getFullYear(), creationDate.getMonth(), 1);
    const lastDayOfMonth = new Date(creationDate.getFullYear(), creationDate.getMonth() + 1, 0);

    const { data: contractData, error: errorContract } = await this.supabaseBypassingRLS
      .from("contract")
      .select("id, workspace_id, talent!inner(id, email), start_date, end_date")
      .eq("talent.email", talentEmail)
      .eq("status", "signed")
      .lte("start_date", this.formatDateForDB(lastDayOfMonth))
      .gte("end_date", this.formatDateForDB(firstDayOfMonth))
      .order("created_at", { ascending: false })
      .limit(1)
      .maybeSingle();

    if (errorContract) throw new CustomError(errorContract.message, 500);
    if (!contractData || !contractData.start_date || !contractData.end_date) {
      const type = "failed_advance_payment_request";
      const notificationData = {
        navigateTo: "/advance-payment-requests/list",
        talent: {
          email: talentEmail,
        },
        creation_date: creationDate,
      };
      const { title, body } = generateNotificationText(
        {
          type,
          data: notificationData,
        },
        this.frontBaseUrl
      );
      await this.notificationService.send({
        title,
        body,
        recipient_roles: ["team_member_developer"],
        type,
        data: notificationData,
      });
      logger.error("No contract found", { talentEmail, creationDate });
      throw new CustomError("No contract found", 400);
    }

    const month = PaidAdvancePaymentHandler.getAdvancePaymentMonth(new Date(contractData.start_date), creationDate);

    // We get all the pending advance payment request for the talent on the SAME MONTH and we cancel them all.
    // It's a security to avoid having multiple pending advance payment request for the same talent at the same time.
    const { data: pendingAdvancePaymentRequest, error: pendingAdvancePaymentRequestError } =
      await this.supabaseBypassingRLS
        .from("advance_payment_request")
        .select("id,status, contract!inner(talent!inner(email))")
        .eq("contract.talent.email", talentEmail)
        .eq("status", "pending");
    if (pendingAdvancePaymentRequestError) {
      throw new CustomError(pendingAdvancePaymentRequestError.message, 500);
    }

    const { error: canceledAdvancePaymentRequestError } =
      await this.supabaseBypassingRLS
        .from("advance_payment_request")
        .update({ status: "canceled" })
        .in(
          "id",
          pendingAdvancePaymentRequest.map((item) => item.id)
        );

    if (canceledAdvancePaymentRequestError) {
      throw new CustomError(canceledAdvancePaymentRequestError.message, 500);
    }

    const { error: errorAdvancePaymentRequest } = await this.supabaseBypassingRLS
      .from("advance_payment_request")
      .insert({
        contract_id: contractData.id,
        talent_id: contractData.talent.id,
        amount,
        real_amount_paid: amount,
        status: "paid",
        workspace_id: contractData.workspace_id,
        month,
        year: 2024, // TODO: remove hardcoded year ?
      })
      .select("id");

    if (errorAdvancePaymentRequest) {
      throw new CustomError(errorAdvancePaymentRequest.message, 500);
    }
  }
}
