import { z } from "zod";

export const teamMemberOffboardingbodySchema = z.union([
  z.object({
    auth_user_id: z.string(),
    draft: z.boolean(),
    todo: z.undefined(),
  }),
  z.object({
    auth_user_id: z.string(),
    draft: z.undefined(),
    todo: z.object({
      ban_back_office: z.boolean(),
      suspend_google_workspace: z.boolean(),
    }),
  }),
]);

export type TeamMemberOffboardingBody = z.infer<typeof teamMemberOffboardingbodySchema>;
export type TeamMemberOffboardingResponse = {
  is_back_office_banned: boolean;
  is_google_workspace_suspended: boolean;
};
