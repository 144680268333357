import { z } from "zod";

export const teamMemberAbsenceBodySchema = z.union([
  z.object({
    team_member_away_id: z.string(),
    revert: z.literal(true),
  }),
  z.object({
    team_member_away_id: z.string(),
    team_member_taking_over_id: z.string(),
    revert: z.literal(false)
  }),
]);

export type TeamMemberAbsenceBody = z.infer<typeof teamMemberAbsenceBodySchema>;
export type TeamMemberAbsenceResponse = {
  team_member_away_id: string;
};
