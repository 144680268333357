<template>
  <ToastRoot v-model:open="open" :duration="toast.duration ?? DEFAULT_DURATION" class="ToastRoot">
    <Icon
      v-if="toast.type === 'success'"
      :icon="mapIcon.check_circle"
      style="color: var(--green); margin-bottom: 2px; font-size: 18px"
    />
    <Icon
      v-else-if="toast.type === 'error'"
      :icon="mapIcon.remove_circle"
      style="color: var(--red); margin-bottom: 2px; font-size: 18px"
    />
    <Icon
      v-else-if="toast.type === 'danger'"
      :icon="mapIcon.remove_circle"
      style="color: var(--red); margin-bottom: 2px; font-size: 18px"
    />
    <div style="display: flex; flex-direction: column; gap: 8px">
      <ToastTitle v-if="toast.title" class="ToastTitle">
        {{ toast.title }}
      </ToastTitle>
      <ToastDescription v-if="toast.description" class="ToastDescription">
        <p v-for="line in toast.description.split('\n')" :key="line">{{ line }}</p>
      </ToastDescription>
      <ToastAction
        v-if="toast.action"
        class="ToastAction"
        as-child
        :alt-text="toast.action.altText ?? toast.action.btnText"
      >
        <Button variant="outline" @click="toast.action.trigger?.()">{{ toast.action.btnText }}</Button>
      </ToastAction>
    </div>

    <ToastClose class="ToastClose">
      <div>
        <Icon :icon="mapIcon.remove" />
      </div>
    </ToastClose>
  </ToastRoot>
</template>

<script setup lang="ts">
import { ToastAction, ToastDescription, ToastRoot, ToastTitle, ToastClose } from "radix-vue";
import type { Toast } from "~/composables/useToast";

defineProps<{ toast: Toast }>();

const open = ref(true);

const DEFAULT_DURATION = 2500;
</script>

<style lang="scss">
.ToastRoot {
  user-select: none;
  touch-action: none;
  background: var(--white);
  border-radius: 10px;
  border: 1px solid var(--gray-6);
  box-shadow: var(--shadow-1);
  padding: 16px;
  display: grid;
  grid-template-areas:
    "icon title action close"
    "icon description action close";
  grid-template-columns: max-content auto max-content max-content;
  column-gap: 8px;
  box-shadow: var(--shadow-3);

  &[data-state="open"] {
    animation: slide-in 150ms cubic-bezier(0.16, 1, 0.3, 1);

    @keyframes slide-in {
      from {
        transform: translateY(calc(100% + var(--viewport-padding)));
      }
      to {
        transform: translateX(0);
      }
    }
  }

  &[data-swipe="move"] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe="cancel"] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe="end"] {
    animation: slideRight 100ms ease-out;

    @keyframes slideRight {
      from {
        transform: translateX(var(--radix-toast-swipe-end-x));
      }
      to {
        transform: translateX(100%);
      }
    }
  }

  &[data-state="close"] {
    animation: hide 100ms ease-in;

    @keyframes hide {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
}

.ToastTitle {
  grid-area: title;
  margin-bottom: 4px;
  font-weight: 500;
  color: var(--gray-2);
  display: flex;
  gap: 8px;
}

.ToastDescription {
  grid-area: description;
  color: var(--gray-2);
  font-size: var(--size-14);
}

.ToastAction {
  grid-area: action;
}

.ToastClose {
  grid-area: close;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  aspect-ratio: 1;
  border-radius: 100px;
  align-self: self-start;
  margin-top: -8px;
  margin-right: -8px;
  font-size: 12px;
  cursor: pointer;
  color: var(--gray-3);
  transition: var(--transition-1);

  &:hover {
    background: var(--gray-7);
  }
}
</style>
