import type { AppRole, NotificationType } from "@asap/shared";

const imageMimetypes = ["image/jpeg", "image/png"] as const;
export const pdfMimetypes = ["application/pdf"] as const;

export const documentMimetypes = [...pdfMimetypes, ...imageMimetypes] as const;
export const officeMimetypes = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
] as const;
export const heicMimetypes = ["image/heic", "image/heif"] as const;

export const businessUnitNames = [
  "CET",
  "TP / VRD",
  "Bâtiment & Gros oeuvre",
  "Télécom et énergies",
  "Constructions modulaires",
  "Management de projets",
  "Clos et couvert",
  "Bureau d'études",
  "Tertiaire",
] as const;

export const importantNotifications: {
  [key in AppRole]?: NotificationType[];
} = {
  team_member_manager_deprecated: ["new_contract_to_process", "new_commercial_condition_created"],
};

export const boardManagerEmails = [
  "baptiste@asap.work",
  "emilie.vialle@asap.work",
  "mathias@asap.work",
  "steve@asap.work",
  "jimmy@asap.work",
  "julien.gaultier@asap.work",
  "francois.gouzon@asap.work",
  "florent.pasquiou@asap.work",
  "guenael@asap.work",
] as const;

export const dashboardKeys = [
  "prodBoardManager",
  "prodConsultant",
  "prodConsultantWithManagedTeamMembers",
  "mapIndependentTeamMemberManager",
] as const;

export const baseUrl = "https://x.asap.work" as const;
