// Define a generic type for the function that will be used in the event hook
type EventFunction<T extends any[] = any[]> = (...args: T) => void;

export const useEventHook = function <T extends any[] = any[]>() {
  // Setup array of functions
  const functions = ref<EventFunction<T>[]>([]);

  // Setup On method to add new functions to the array of functions
  const on = function (func: EventFunction<T>) {
    // Inject new function into the array of functions
    functions.value = [...functions.value, func];
  };

  // Clean up all functions
  const cleanup = () => {
    functions.value = [];
  };

  // Setup Trigger method to execute all functions in the array
  const trigger = function (...args: T) {
    // Iterate over each function in the array
    for (const func of functions.value) {
      // Execute function by passing arguments
      try {
        func(...args);
      } catch {
        //
      }
    }
  };

  return {
    on,
    trigger,
    cleanup,
  };
};
