import type { Database } from "../types/supabase.gen.ts";


type SupabaseEnum = keyof Database["public"]["Enums"];
type ExtractEnum<SupabaseEnumName extends SupabaseEnum> = Database["public"]["Enums"][SupabaseEnumName];


export enum AcquisitionSourceSupabaseEnum {
  website = "website",
  prospecting = "prospecting",
  event = "event",
  other = "other",
  apec__multidiffusion = "apec__multidiffusion",
  apec__sourcing = "apec__sourcing",
  beetween__import = "beetween__import",
  cadremploi__multidiffusion = "cadremploi__multidiffusion",
  direct_marketing__email = "direct_marketing__email",
  direct_marketing__push = "direct_marketing__push",
  direct_marketing__sms = "direct_marketing__sms",
  direct_marketing__whatsapp = "direct_marketing__whatsapp",
  emploi_interim__multidiffusion = "emploi_interim__multidiffusion",
  facebook_ads__carousel = "facebook_ads__carousel",
  facebook_ads__image = "facebook_ads__image",
  facebook_ads__lead = "facebook_ads__lead",
  facebook_ads__video = "facebook_ads__video",
  figaro_emploi__multidiffusion = "figaro_emploi__multidiffusion",
  google_ads__display = "google_ads__display",
  google_ads__search = "google_ads__search",
  google_ads__video = "google_ads__video",
  hellowork__multidiffusion = "hellowork__multidiffusion",
  hellowork__researcher = "hellowork__researcher",
  hellowork__sourcing = "hellowork__sourcing",
  hubspot__import = "hubspot__import",
  indeed__multidiffusion = "indeed__multidiffusion",
  inrecruiting__import = "inrecruiting__import",
  linkedin__sourcing = "linkedin__sourcing",
  linkedin_ads__carousel = "linkedin_ads__carousel",
  linkedin_ads__image = "linkedin_ads__image",
  linkedin_ads__lead = "linkedin_ads__lead",
  linkedin_ads__text = "linkedin_ads__text",
  linkedin_ads__video = "linkedin_ads__video",
  meteojob__multidiffusion = "meteojob__multidiffusion",
  meteojob__researcher = "meteojob__researcher",
  ordre_des_architectes__multidiffusion = "ordre_des_architectes__multidiffusion",
  pole_emploi__multidiffusion = "pole_emploi__multidiffusion",
  pole_emploi__researcher = "pole_emploi__researcher",
  pole_emploi__sourcing = "pole_emploi__sourcing",
  social_media__facebook = "social_media__facebook",
  social_media__instagram = "social_media__instagram",
  social_media__linkedin = "social_media__linkedin",
  social_media__tiktok = "social_media__tiktok",
  social_media__youtube = "social_media__youtube",
  sponsoring = "sponsoring",
  co_marketing = "co_marketing",
  newsletter = "newsletter",
  referral = "referral",
  emailing = "emailing",
  cvaden__sourcing = "cvaden__sourcing",
  meteojob__sourcing = "meteojob__sourcing",
  linkedin__multidiffusion = "linkedin__multidiffusion",
  directemploi__multidiffusion = "directemploi__multidiffusion",
  other__multidiffusion = "other__multidiffusion",
  facebook__sourcing = "facebook__sourcing",
  facebook__multidiffusion = "facebook__multidiffusion",
  facebook__marketplace = "facebook__marketplace",
  portage__sourcing = "portage__sourcing"
}
export type AcquisitionSourceSupabaseEnumType = ExtractEnum<"acquisition_source">;

export enum AddressTypeSupabaseEnum {
  street = "street",
  city = "city",
  department = "department",
  region = "region",
  country = "country"
}
export type AddressTypeSupabaseEnumType = ExtractEnum<"address_type">;

export enum AdvancePaymentRequestStatusSupabaseEnum {
  pending = "pending",
  paid = "paid",
  approved = "approved",
  canceled = "canceled",
  refused = "refused",
  error = "error",
  pending_transfer_confirmation = "pending_transfer_confirmation"
}
export type AdvancePaymentRequestStatusSupabaseEnumType = ExtractEnum<"advance_payment_request_status">;

export enum AdvancePaymentRequestTypeSupabaseEnum {
  manual = "manual",
  automatic = "automatic"
}
export type AdvancePaymentRequestTypeSupabaseEnumType = ExtractEnum<"advance_payment_request_type">;

export enum AmendmentTypeSupabaseEnum {
  extension = "extension",
  modification = "modification"
}
export type AmendmentTypeSupabaseEnumType = ExtractEnum<"amendment_type">;

export enum ApiCallerSupabaseEnum {
  make__facebook_ads__lead = "make__facebook_ads__lead",
  make__website__form = "make__website__form",
  app__talent = "app__talent",
  app__back_office = "app__back_office",
  make__multidiffusion__broadbeen = "make__multidiffusion__broadbeen",
  legacy__xano = "legacy__xano",
  app__researcher = "app__researcher",
  sync__inrecruiting = "sync__inrecruiting",
  make__multidiffusion__talentplug = "make__multidiffusion__talentplug"
}
export type ApiCallerSupabaseEnumType = ExtractEnum<"api_caller">;

export enum AppRoleSupabaseEnum {
  team_member = "team_member",
  talent = "talent",
  super_team_member_deprecated = "super_team_member_deprecated",
  accounting_team_member = "accounting_team_member",
  csm_team_member = "csm_team_member",
  consultant_team_member = "consultant_team_member",
  team_member_manager_deprecated = "team_member_manager_deprecated",
  team_member_marketing = "team_member_marketing",
  team_member_developer = "team_member_developer",
  team_member_independent_administrator = "team_member_independent_administrator",
  external_csm_team_member = "external_csm_team_member"
}
export type AppRoleSupabaseEnumType = ExtractEnum<"app_role">;

export enum ApplicationOmogenStatusSupabaseEnum {
  pending = "pending",
  called = "called",
  parsed = "parsed",
  error = "error"
}
export type ApplicationOmogenStatusSupabaseEnumType = ExtractEnum<"application_omogen_status">;

export enum ApplicationStageSupabaseEnum {
  inbox = "inbox",
  shortlist = "shortlist",
  ready_to_submit = "ready_to_submit",
  client_review = "client_review",
  interview = "interview",
  accepted = "accepted"
}
export type ApplicationStageSupabaseEnumType = ExtractEnum<"application_stage">;

export enum BesttNatureSupabaseEnum {
  B = "B",
  N = "N",
  R = "R"
}
export type BesttNatureSupabaseEnumType = ExtractEnum<"besttNature">;

export enum CivilitySupabaseEnum {
  mister = "mister",
  madam = "madam"
}
export type CivilitySupabaseEnumType = ExtractEnum<"civility">;

export enum CommercialConditionPricingModelSupabaseEnum {
  grid = "grid",
  profile = "profile",
  job = "job"
}
export type CommercialConditionPricingModelSupabaseEnumType = ExtractEnum<"commercial_condition_pricing_model">;

export enum CommercialConditionStatusSupabaseEnum {
  not_signed = "not_signed",
  signed = "signed",
  refused = "refused",
  to_validate = "to_validate",
  canceled = "canceled",
  sent = "sent"
}
export type CommercialConditionStatusSupabaseEnumType = ExtractEnum<"commercial_condition_status">;

export enum CommercialConditionTypeSupabaseEnum {
  interim = "interim",
  recruitment_office = "recruitment_office",
  interim_and_recruitment_office = "interim_and_recruitment_office"
}
export type CommercialConditionTypeSupabaseEnumType = ExtractEnum<"commercial_condition_type">;

export enum CompanyInsuranceRequestStatusSupabaseEnum {
  new = "new",
  sent = "sent",
  approved = "approved",
  refused = "refused",
  approved_temporarily = "approved_temporarily",
  approved_but_only_contract_minimum = "approved_but_only_contract_minimum",
  approved_but_maximum_reached = "approved_but_maximum_reached",
  terminated = "terminated"
}
export type CompanyInsuranceRequestStatusSupabaseEnumType = ExtractEnum<"company_insurance_request_status">;

export enum ConstructionSiteSizeSupabaseEnum {
  less_than_100K = "less_than_100K",
  from_100K_to_500K = "from_100K_to_500K",
  from_500K_to_1M = "from_500K_to_1M",
  from_1M_to_10M = "from_1M_to_10M",
  more_than_10M = "more_than_10M"
}
export type ConstructionSiteSizeSupabaseEnumType = ExtractEnum<"construction_site_size">;

export enum ConstructionTypeSupabaseEnum {
  new_build = "new_build",
  renovation = "renovation",
  rehabilitation = "rehabilitation",
  maintenance = "maintenance"
}
export type ConstructionTypeSupabaseEnumType = ExtractEnum<"construction_type">;

export enum ContractContactTypeSupabaseEnum {
  timesheet_signer = "timesheet_signer",
  decision_maker = "decision_maker",
  admin = "admin",
  accounting = "accounting"
}
export type ContractContactTypeSupabaseEnumType = ExtractEnum<"contract_contact_type">;

export enum ContractDocumentTypeSupabaseEnum {
  contract_talent = "contract_talent",
  contract_client = "contract_client",
  contract_client_signed = "contract_client_signed",
  contract_talent_signed = "contract_talent_signed",
  pre_employment_declaration = "pre_employment_declaration",
  other = "other"
}
export type ContractDocumentTypeSupabaseEnumType = ExtractEnum<"contract_document_type">;

export enum ContractReasonSupabaseEnum {
  increased_company_actvity = "increased_company_actvity",
  employee_replacement = "employee_replacement",
  talent_professional_training = "talent_professional_training"
}
export type ContractReasonSupabaseEnumType = ExtractEnum<"contract_reason">;

export enum ContractStatusSupabaseEnum {
  draft = "draft",
  to_process = "to_process",
  to_send = "to_send",
  waiting_for_signature = "waiting_for_signature",
  signed = "signed",
  cancelled = "cancelled",
  to_validate = "to_validate"
}
export type ContractStatusSupabaseEnumType = ExtractEnum<"contract_status">;

export enum ContractSubjectSupabaseEnum {
  activity_increase = "activity_increase",
  professional_training = "professional_training",
  employee_replacement = "employee_replacement"
}
export type ContractSubjectSupabaseEnumType = ExtractEnum<"contract_subject">;

export enum ContractTypeSupabaseEnum {
  permanent = "permanent",
  temporary = "temporary",
  interim = "interim"
}
export type ContractTypeSupabaseEnumType = ExtractEnum<"contract_type">;

export enum DayOfWeekSupabaseEnum {
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
  sunday = "sunday"
}
export type DayOfWeekSupabaseEnumType = ExtractEnum<"day_of_week">;

export enum DefinitiveEndTypeSupabaseEnum {
  sick_leave = "sick_leave",
  found_permanent_job_elsewhere = "found_permanent_job_elsewhere",
  technical_bug = "technical_bug",
  worker_leave = "worker_leave",
  permanent_hire = "permanent_hire",
  permanent_hire_by_current_client = "permanent_hire_by_current_client",
  end_of_mission_by_worker = "end_of_mission_by_worker",
  end_of_mission = "end_of_mission",
  competition_flip_flop = "competition_flip_flop",
  not_qualified = "not_qualified",
  no_show = "no_show",
  talent_no_show = "talent_no_show",
  client_no_show = "client_no_show",
  contract_renewal = "contract_renewal",
  negative_flexibility = "negative_flexibility",
  positive_flexibility = "positive_flexibility",
  other = "other",
  end_of_mission_by_client = "end_of_mission_by_client",
  contract_new = "contract_new",
  will_no_longer_work_with_us = "will_no_longer_work_with_us",
  end_of_trial_period = "end_of_trial_period",
  contract_new_with_different_client = "contract_new_with_different_client"
}
export type DefinitiveEndTypeSupabaseEnumType = ExtractEnum<"definitive_end_type">;

export enum DocumentTypeSupabaseEnum {
  admin__identity__id_card = "admin__identity__id_card",
  admin__identity__passeport = "admin__identity__passeport",
  admin__identity__residence_permit = "admin__identity__residence_permit",
  admin__identity__residence_permit_receipt = "admin__identity__residence_permit_receipt",
  admin__banking__rib = "admin__banking__rib",
  admin__health_insurance__vital_card = "admin__health_insurance__vital_card",
  admin__health_insurance__social_security_certificate = "admin__health_insurance__social_security_certificate",
  admin__health_insurance__european_health_insurance_card = "admin__health_insurance__european_health_insurance_card",
  admin__residence__proof_of_address = "admin__residence__proof_of_address",
  admin__residence__accomodation_certificate = "admin__residence__accomodation_certificate",
  admin__residence__proof_of_identity_of_the_host = "admin__residence__proof_of_identity_of_the_host",
  admin__residence__proof_of_address_of_the_host = "admin__residence__proof_of_address_of_the_host",
  professional__driving_licence = "professional__driving_licence",
  professional__qualification_certificate = "professional__qualification_certificate",
  other__legal__criminal_record = "other__legal__criminal_record",
  other__misc = "other__misc",
  professional__cover_letter = "professional__cover_letter",
  professional__reference_letter = "professional__reference_letter",
  professional__professional_card_btp = "professional__professional_card_btp",
  admin__health__medical_certificate = "admin__health__medical_certificate",
  contract__recruitment_office__invoice_draft = "contract__recruitment_office__invoice_draft",
  contract__recruitment_office__invoice_real = "contract__recruitment_office__invoice_real",
  admin__health__medical_convocation = "admin__health__medical_convocation",
  admin__qualification_training_request = "admin__qualification_training_request",
  other__company_insurance__response = "other__company_insurance__response",
  admin__contract__document = "admin__contract__document",
  admin__health__work_accident_submission_certificate = "admin__health__work_accident_submission_certificate",
  admin__health__work_accident_work_interruption_certificate = "admin__health__work_accident_work_interruption_certificate"
}
export type DocumentTypeSupabaseEnumType = ExtractEnum<"document_type">;

export enum EmployeeStatusSupabaseEnum {
  worker = "worker",
  technician = "technician",
  executive = "executive"
}
export type EmployeeStatusSupabaseEnumType = ExtractEnum<"employee_status">;

export enum ExpenseClaimRefundScheduleSupabaseEnum {
  immediately = "immediately",
  at_next_payroll = "at_next_payroll"
}
export type ExpenseClaimRefundScheduleSupabaseEnumType = ExtractEnum<"expense_claim_refund_schedule">;

export enum ExpenseClaimRequestTypeSupabaseEnum {
  ppe = "ppe",
  transportation = "transportation",
  mileage = "mileage",
  tooling = "tooling",
  food = "food",
  accommodation = "accommodation",
  travel = "travel"
}
export type ExpenseClaimRequestTypeSupabaseEnumType = ExtractEnum<"expense_claim_request_type">;

export enum ExperienceLevelSupabaseEnum {
  from_0_to_1_year = "from_0_to_1_year",
  from_1_to_3_years = "from_1_to_3_years",
  from_3_to_6_years = "from_3_to_6_years",
  from_6_to_10_years = "from_6_to_10_years",
  more_than_10_years = "more_than_10_years"
}
export type ExperienceLevelSupabaseEnumType = ExtractEnum<"experience_level">;

export enum FileSourceSupabaseEnum {
  filestack = "filestack",
  supabase = "supabase"
}
export type FileSourceSupabaseEnumType = ExtractEnum<"file_source">;

export enum FollowingStatusSupabaseEnum {
  pending = "pending",
  approved = "approved",
  refused = "refused",
  canceled = "canceled"
}
export type FollowingStatusSupabaseEnumType = ExtractEnum<"following_status">;

export enum GenderTypeSupabaseEnum {
  male = "male",
  female = "female",
  neutral = "neutral"
}
export type GenderTypeSupabaseEnumType = ExtractEnum<"gender_type">;

export enum IndependentPaymentRequestStatusSupabaseEnum {
  pending = "pending",
  refused = "refused",
  approved = "approved",
  paid = "paid",
  canceled = "canceled"
}
export type IndependentPaymentRequestStatusSupabaseEnumType = ExtractEnum<"independent_payment_request_status">;

export enum JobPostingStatusSupabaseEnum {
  draft = "draft",
  published = "published",
  archived = "archived"
}
export type JobPostingStatusSupabaseEnumType = ExtractEnum<"job_posting_status">;

export enum JobQualificationLevelSupabaseEnum {
  low = "low",
  middle = "middle",
  high = "high"
}
export type JobQualificationLevelSupabaseEnumType = ExtractEnum<"job_qualification_level">;

export enum JobRiskSupabaseEnum {
  use_of_dangerous_tools = "use_of_dangerous_tools",
  handling_heavy_objects = "handling_heavy_objects",
  manual_handling = "manual_handling",
  working_under_traffic = "working_under_traffic",
  burial_or_sinking = "burial_or_sinking",
  use_of_chemical_products = "use_of_chemical_products",
  dust_treatments_milling = "dust_treatments_milling",
  electrical_risks = "electrical_risks",
  fall_from_height = "fall_from_height",
  demolition = "demolition"
}
export type JobRiskSupabaseEnumType = ExtractEnum<"job_risk">;

export enum LoyaltyLevelSupabaseEnum {
  sapphire = "sapphire",
  emerald = "emerald",
  ruby = "ruby"
}
export type LoyaltyLevelSupabaseEnumType = ExtractEnum<"loyalty_level">;

export enum MedicalCenterStatusSupabaseEnum {
  pending = "pending",
  approved = "approved",
  refused = "refused"
}
export type MedicalCenterStatusSupabaseEnumType = ExtractEnum<"medical_center_status">;

export enum MedicalCheckupStatusSupabaseEnum {
  todo = "todo",
  pending = "pending",
  approved = "approved",
  done = "done",
  canceled = "canceled"
}
export type MedicalCheckupStatusSupabaseEnumType = ExtractEnum<"medical_checkup_status">;

export enum MedicalCheckupTypeSupabaseEnum {
  VIPI = "VIPI",
  SIR = "SIR",
  SIA = "SIA"
}
export type MedicalCheckupTypeSupabaseEnumType = ExtractEnum<"medical_checkup_type">;

export enum MissionStatusSupabaseEnum {
  active = "active",
  monitoring = "monitoring",
  inactive = "inactive",
  completed = "completed",
  canceled = "canceled",
  completed_by_client = "completed_by_client",
  completed_by_concurrence = "completed_by_concurrence"
}
export type MissionStatusSupabaseEnumType = ExtractEnum<"mission_status">;

export enum MissionTypeSupabaseEnum {
  works = "works",
  design_office_conception = "design_office_conception",
  design_office_execution = "design_office_execution"
}
export type MissionTypeSupabaseEnumType = ExtractEnum<"mission_type">;

export enum MonthSupabaseEnum {
  january = "january",
  february = "february",
  march = "march",
  april = "april",
  may = "may",
  june = "june",
  july = "july",
  august = "august",
  september = "september",
  october = "october",
  november = "november",
  december = "december"
}
export type MonthSupabaseEnumType = ExtractEnum<"month">;

export enum NotificationTypeSupabaseEnum {
  new_professional_card_request = "new_professional_card_request",
  new_contract_to_process = "new_contract_to_process",
  new_definitive_end_on_contract_to_process = "new_definitive_end_on_contract_to_process",
  new_medical_checkup_request = "new_medical_checkup_request",
  new_advance_payment_request = "new_advance_payment_request",
  new_qualification_training_request = "new_qualification_training_request",
  new_expense_claim_request_to_validate = "new_expense_claim_request_to_validate",
  first_contract_signed_by_talent = "first_contract_signed_by_talent",
  failed_advance_payment_request = "failed_advance_payment_request",
  talent_offboarding_needed = "talent_offboarding_needed",
  approved_recruitment_office_invoice_request = "approved_recruitment_office_invoice_request",
  refused_recruitment_office_invoice_request = "refused_recruitment_office_invoice_request",
  new_recruitment_office_invoice_request = "new_recruitment_office_invoice_request",
  new_contract_to_send = "new_contract_to_send",
  new_onboarding_app_needed = "new_onboarding_app_needed",
  pending_recruitment_office_invoice_request = "pending_recruitment_office_invoice_request",
  done_recruitment_office_invoice_request = "done_recruitment_office_invoice_request",
  new_qualification_training_request_invoicing_document = "new_qualification_training_request_invoicing_document",
  cancel_medical_checkup_request_on_definitive_contract_end = "cancel_medical_checkup_request_on_definitive_contract_end",
  new_company_insurance_request = "new_company_insurance_request",
  end_of_company_insurance_request = "end_of_company_insurance_request",
  new_qualification_training_request_certificate_document = "new_qualification_training_request_certificate_document",
  failed_website_job_posting = "failed_website_job_posting",
  first_contract_signed_by_independent = "first_contract_signed_by_independent",
  new_qualification_training_commitment = "new_qualification_training_commitment",
  new_contract_document_pre_employment_declaration = "new_contract_document_pre_employment_declaration",
  new_professional_card_request_document_uploaded = "new_professional_card_request_document_uploaded",
  new_mission_to_post_on_job_boards = "new_mission_to_post_on_job_boards",
  archived_job_offer = "archived_job_offer",
  new_contract_signed = "new_contract_signed",
  expiring_document = "expiring_document",
  new_commercial_condition_created = "new_commercial_condition_created",
  new_contract_to_validate = "new_contract_to_validate",
  medical_checkup_request_done = "medical_checkup_request_done",
  new_ppe_request = "new_ppe_request",
  each_commercial_condition_validated = "each_commercial_condition_validated",
  mobile_app__timesheet_fully_approved = "mobile_app__timesheet_fully_approved",
  mobile_app__talent_timesheet_reminder = "mobile_app__talent_timesheet_reminder",
  mobile_app__talent_contract_signed = "mobile_app__talent_contract_signed",
  mobile_app__timesheet_rejected_by_client = "mobile_app__timesheet_rejected_by_client",
  job_offer_republish = "job_offer_republish",
  new_request_push_marketing = "new_request_push_marketing",
  new_expense_claim_request_to_process = "new_expense_claim_request_to_process",
  new_work_accident_request = "new_work_accident_request",
  work_accident_request_done = "work_accident_request_done",
  medical_checkup_request_approved = "medical_checkup_request_approved",
  qualification_training_request_approved = "qualification_training_request_approved",
  new_contract_declined = "new_contract_declined",
  turned_off_push_marketing = "turned_off_push_marketing",
  new_rib_uploaded = "new_rib_uploaded"
}
export type NotificationTypeSupabaseEnumType = ExtractEnum<"notification_type">;

export enum OnboardingRequestStatusSupabaseEnum {
  todo = "todo",
  pending = "pending",
  done = "done",
  canceled = "canceled"
}
export type OnboardingRequestStatusSupabaseEnumType = ExtractEnum<"onboarding_request_status">;

export enum PayTrackingStatusSupabaseEnum {
  to_complete = "to_complete",
  to_process = "to_process",
  to_verify = "to_verify",
  to_send = "to_send",
  proceed = "proceed"
}
export type PayTrackingStatusSupabaseEnumType = ExtractEnum<"pay_tracking_status">;

export enum PpeSupabaseEnum {
  safety_shoes = "safety_shoes",
  safety_helmet = "safety_helmet",
  protective_glasses = "protective_glasses",
  protective_gloves = "protective_gloves",
  hearing_protection_helmet = "hearing_protection_helmet",
  safety_vest = "safety_vest",
  safety_pants = "safety_pants"
}
export type PpeSupabaseEnumType = ExtractEnum<"ppe">;

export enum PpeRequestStatusSupabaseEnum {
  pending = "pending",
  approved = "approved",
  refused = "refused",
  todo = "todo"
}
export type PpeRequestStatusSupabaseEnumType = ExtractEnum<"ppe_request_status">;

export enum QualificationCenterStatusSupabaseEnum {
  pending = "pending",
  approved = "approved",
  refused = "refused"
}
export type QualificationCenterStatusSupabaseEnumType = ExtractEnum<"qualification_center_status">;

export enum QualificationCenterTrainingTypeSupabaseEnum {
  remote = "remote",
  in_person = "in-person",
  hybrid = "hybrid"
}
export type QualificationCenterTrainingTypeSupabaseEnumType = ExtractEnum<"qualification_center_training_type">;

export enum QualificationTrainingRequestDocumentTypeSupabaseEnum {
  invoicing = "invoicing",
  convocation = "convocation",
  certificate = "certificate",
  other = "other"
}
export type QualificationTrainingRequestDocumentTypeSupabaseEnumType = ExtractEnum<"qualification_training_request_document_type">;

export enum QualificationTrainingRequestStatusSupabaseEnum {
  todo = "todo",
  pending = "pending",
  approved = "approved",
  done = "done",
  canceled = "canceled"
}
export type QualificationTrainingRequestStatusSupabaseEnumType = ExtractEnum<"qualification_training_request_status">;

export enum RecruitmentOfficeInvoiceRequestStatusSupabaseEnum {
  new = "new",
  pending = "pending",
  refused = "refused",
  approved = "approved",
  done = "done",
  canceled = "canceled"
}
export type RecruitmentOfficeInvoiceRequestStatusSupabaseEnumType = ExtractEnum<"recruitment_office_invoice_request_status">;

export enum ReferenceCheckStatusSupabaseEnum {
  old = "old",
  todo = "todo",
  done = "done",
  canceled = "canceled"
}
export type ReferenceCheckStatusSupabaseEnumType = ExtractEnum<"reference_check_status">;

export enum RequestWithValidationStatusSupabaseEnum {
  to_validate = "to_validate",
  pending = "pending",
  approved = "approved",
  refused = "refused",
  canceled = "canceled"
}
export type RequestWithValidationStatusSupabaseEnumType = ExtractEnum<"request_with_validation_status">;

export enum RibCreationTypeSupabaseEnum {
  import_from_bestt = "import_from_bestt",
  extraction_from_app = "extraction_from_app",
  manual_from_app = "manual_from_app",
  automatic_extraction_from_document = "automatic_extraction_from_document"
}
export type RibCreationTypeSupabaseEnumType = ExtractEnum<"rib_creation_type">;

export enum RunStatusSupabaseEnum {
  not_started = "not_started",
  in_progress = "in_progress",
  error = "error",
  success = "success"
}
export type RunStatusSupabaseEnumType = ExtractEnum<"run_status">;

export enum SirhSupabaseEnum {
  baps = "baps",
  peopulse = "peopulse",
  pixid = "pixid",
  by_hand = "by_hand"
}
export type SirhSupabaseEnumType = ExtractEnum<"sirh">;

export enum SoftwareMasteryLevelSupabaseEnum {
  beginner = "beginner",
  junior = "junior",
  confirmed = "confirmed",
  senior = "senior",
  expert = "expert"
}
export type SoftwareMasteryLevelSupabaseEnumType = ExtractEnum<"software_mastery_level">;

export enum StatusSequenceSupabaseEnum {
  not_started = "not_started",
  in_progress = "in_progress",
  completed = "completed",
  canceled = "canceled"
}
export type StatusSequenceSupabaseEnumType = ExtractEnum<"status_sequence">;

export enum TalentAvailabilityStatusSupabaseEnum {
  available_now = "available_now",
  available_soon = "available_soon",
  not_available = "not_available"
}
export type TalentAvailabilityStatusSupabaseEnumType = ExtractEnum<"talent_availability_status">;

export enum TalentQualificationStatusSupabaseEnum {
  not_started = "not_started",
  in_progress = "in_progress",
  completed = "completed",
  disqualified = "disqualified",
  blacklisted = "blacklisted"
}
export type TalentQualificationStatusSupabaseEnumType = ExtractEnum<"talent_qualification_status">;

export enum TeamSizeSupabaseEnum {
  less_than_10_people = "less_than_10_people",
  from_10_to_20_people = "from_10_to_20_people",
  from_20_to_50_people = "from_20_to_50_people",
  more_than_50_people = "more_than_50_people"
}
export type TeamSizeSupabaseEnumType = ExtractEnum<"team_size">;

export enum TimesheetDayStatusSupabaseEnum {
  to_modify = "to_modify",
  validated = "validated",
  completed = "completed",
  filled = "filled"
}
export type TimesheetDayStatusSupabaseEnumType = ExtractEnum<"timesheet_day_status">;

export enum TimesheetWeekStatusSupabaseEnum {
  fully_approved = "fully_approved",
  completed_by_talent = "completed_by_talent",
  completed_by_client = "completed_by_client",
  rejected_by_talent = "rejected_by_talent",
  rejected_by_client = "rejected_by_client",
  not_completed = "not_completed"
}
export type TimesheetWeekStatusSupabaseEnumType = ExtractEnum<"timesheet_week_status">;

export enum WorkAccidentRequestStatusSupabaseEnum {
  todo = "todo",
  pending = "pending",
  done = "done",
  canceled = "canceled"
}
export type WorkAccidentRequestStatusSupabaseEnumType = ExtractEnum<"work_accident_request_status">;