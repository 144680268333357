import { z } from "zod";
import { MonthSupabaseEnum, PayTrackingStatusSupabaseEnum } from "../types/supabase.enum.gen.ts";

export const payTrackingRequestSchema = z.object({
  month: z.nativeEnum(MonthSupabaseEnum),
});

const payTrackingTimesheetDaySchema = z.object({
  id: z.string(),
  date: z.string(),
  day_minutes: z.number().nonnegative(),
  night_minutes: z.number().nonnegative(),
});

const payTrackingConsultantSchema = z.object({
  id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
});

const payTrackingContractSchema = z.object({
  contract_key: z.number().positive(),
  start_date: z.string(),
  end_date: z.string(),
  hourly_wage: z.number().positive(),
  coefficient: z.number().positive(),
  timesheet_days: z.array(payTrackingTimesheetDaySchema).default([]),
  consultant: payTrackingConsultantSchema,
});

const payTrackingTalentSchema = z.object({
  id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
});

const payTrackingAccountingSchema = z.object({
  id: z.string().nullable(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  email: z.string().nullable(),
});

export const payTrackingResultSchema = z.array(
  z.object({
    id: z.string(),
    month: z.nativeEnum(MonthSupabaseEnum),
    talent: payTrackingTalentSchema,
    accounting: payTrackingAccountingSchema,
    status: z.nativeEnum(PayTrackingStatusSupabaseEnum),
    contracts: z.array(payTrackingContractSchema),
  })
);
