import { z } from "zod";

type OutputSuccess<Data> = { success: true; data: Data };
type OutputError = {
  success: false;
  error: {
    code: number;
    message: string;
  };
};
export type JobPostingResponse<Data> = OutputError | OutputSuccess<Data>;

export type JobBoard = {
  name: string;
  status: string;
  reason: string;
};

export type GetJobOfferResponse =
  | {
      status: "draft" | "published" | "archived";
      job_boards: JobBoard[];
      mission_start_date?: Date;

      company_url?: string;
      company_description?: string;

      contact_name?: string;
      contact_phone?: string;

      posting_date?: Date;
      title: string;
      description?: string;
      salary_benefits?: string;
      applicant_profile?: string;
      experience_level?: (typeof experienceLevels)[number];
      contract_type?: (typeof contractTypes)[number];
      employee_status?: (typeof jobOfferEmployeeStatus)[number];
      address?: z.infer<typeof addressSchema>;

      salary_text?: string;
    }
  | Record<string, never>; // Empty object when 404 error

export type CreateJobOfferResponse =
  | {
      url: string;
    }
  | { id: string };
export type UpdateJobOfferResponse = CreateJobOfferResponse;

export type DeleteJobOfferResponse = {
  mission_id: string;
};

export type RepublishJobOfferResponse = {
  mission_id: string;
};

export interface JobPostingService {
  createJobOffer: (body: CreateJobOfferBody) => Promise<JobPostingResponse<CreateJobOfferResponse>>;
  getJobOffer: (body: GetJobOfferBody) => Promise<JobPostingResponse<GetJobOfferResponse>>;
  deleteJobOffer: (body: DeleteJobOfferBody) => Promise<JobPostingResponse<DeleteJobOfferResponse>>;
  updateJobOffer: (body: UpdateJobOfferBody) => Promise<JobPostingResponse<UpdateJobOfferResponse>>;
  republishJobOffer: (body: RepublishJobOfferBody) => Promise<JobPostingResponse<RepublishJobOfferResponse>>;
}

const experienceLevels = [
  "from_0_to_1_year",
  "from_1_to_3_years",
  "from_3_to_6_years",
  "from_6_to_10_years",
  "more_than_10_years",
] as const;

const contractTypes = ["permanent", "temporary", "interim"] as const;

const jobOfferEmployeeStatus = [
  "employee",
  "operator",
  "worker",
  "worker_specialized",
  "technician",
  "etam",
  "engineer",
  "executive",
  "executive_manager",
] as const;
export type JobOfferEmployeeStatus = (typeof jobOfferEmployeeStatus)[number];

const addressSchema = z.object({
  formatted: z.string().nullable().optional(),
  street1: z.string().nullable().optional(),
  street2: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  postal_code: z.string().nullable().optional(),
  department: z.string().nullable().optional(),
  region: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
  coordinates: z
    .object({
      lat: z.number().nullable(),
      lng: z.number().nullable(),
    })
    .nullable()
    .optional(),
});
export type Address = z.infer<typeof addressSchema>;

const createJobOfferSchema = z
  .object({
    action: z.literal("create_job_offer"),
    mission_id: z.string().uuid(),

    mission_start_date: z.coerce.date().optional(),
    mission_end_date: z.coerce.date().optional(),

    company_url: z.string().url().optional(),
    company_description: z.string().max(2500).optional(),

    applicant_profile: z.string().max(2500).optional(),
    // applicant_skills: z.string().max(75).optional(),

    contact_name: z.string().optional(),
    contact_phone: z.string().optional(),

    posting_date: z.coerce.date().optional(),
    title: z.string().max(75),
    description: z.string().max(2500),
    salary_benefits: z.string().max(75).optional(),
    experience_level: z.enum(experienceLevels).optional(),
    contract_type: z.enum(contractTypes).optional(),
    employee_status: z.enum(jobOfferEmployeeStatus).optional(),
    address: addressSchema.nullable().optional(),

    business_unit: z
      .object({
        name: z.string(),
        webflow_id: z.string(),
      })
      .optional(),

    salary_text: z.string().optional(),
  })
  .strict();
export type CreateJobOfferBody = Omit<z.infer<typeof createJobOfferSchema>, "action">;

const getJobOfferSchema = z
  .object({
    action: z.literal("get_job_offer"),
    mission_id: z.string().uuid(),
  })
  .strict();
export type GetJobOfferBody = Omit<z.infer<typeof getJobOfferSchema>, "action">;

const deleteJobOfferSchema = z
  .object({
    action: z.literal("delete_job_offer"),
    mission_id: z.string().uuid(),
  })
  .strict();
export type DeleteJobOfferBody = Omit<z.infer<typeof deleteJobOfferSchema>, "action">;

const republishJobOfferSchema = z
  .object({
    action: z.literal("republish_job_offer"),
    mission_id: z.string().uuid(),
  })
  .strict();
export type RepublishJobOfferBody = Omit<z.infer<typeof republishJobOfferSchema>, "action">;

const updateJobOfferSchema = z
  .object({
    action: z.literal("update_job_offer"),
    mission_id: z.string().uuid(),

    mission_start_date: z.coerce.date().optional(),
    mission_end_date: z.coerce.date().optional(),

    applicant_profile: z.string().max(2500).optional(),

    company_url: z.string().url().optional(),
    company_description: z.string().max(2500).optional(),

    posting_date: z.coerce.date().optional(),
    title: z.string().max(75).optional(),
    description: z.string().max(2500).optional(),
    salary_benefits: z.string().max(75).optional(),
    experience_level: z.enum(experienceLevels).optional(),
    contract_type: z.enum(contractTypes).optional(),
    employee_status: z.enum(jobOfferEmployeeStatus).optional(),
    address: addressSchema.optional(),
  })
  .strict();
export type UpdateJobOfferBody = Omit<z.infer<typeof updateJobOfferSchema>, "action">;

export const jobPostingSchema = z.discriminatedUnion("action", [
  createJobOfferSchema,
  getJobOfferSchema,
  deleteJobOfferSchema,
  updateJobOfferSchema,
  republishJobOfferSchema,
]);
export type JobPostingBody = z.infer<typeof jobPostingSchema>;
