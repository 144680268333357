import { z } from "zod";

export const jobPostingHandlerSchema = z.discriminatedUnion("action", [
  z
    .object({
      action: z.literal("get_job_offer"),
      mission_id: z.string().uuid(),
    })
    .strict(),
  z
    .object({
      action: z.literal("create_job_offer"),
      mission_id: z.string().uuid(),
    })
    .strict(),
  z
    .object({
      action: z.literal("delete_job_offer"),
      mission_id: z.string().uuid(),
    })
    .strict(),
  z
    .object({
      action: z.literal("update_job_offer"),
      mission_id: z.string().uuid(),
    })
    .strict(),
  z
    .object({
      action: z.literal("republish_job_offer"),
      mission_id: z.string().uuid(),
    })
    .strict(),
]);
export type JobPostingHandlerBody = z.infer<typeof jobPostingHandlerSchema>;
